import React from "react"
import { useStaticQuery } from "gatsby"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import CS01Intro from "../components/CaseStudy/CS01Intro"
import CS02Video from "../components/CaseStudy/CS02Video"
import CS03Process from "../components/CaseStudy/CS03Process"
import CS04Gallery from "../components/CaseStudy/CS04Gallery"
import CS05Related from "../components/CaseStudy/CS05Related"

import caseStudies from "../data/caseStudies.json"

const CaseStudyTemplate = ({ pageContext: { data } }) => {
  const {
    id,
    title,
    introduction,
    services,
    client: { title: client },
    clientQuote,
    clientQuoteBy,
    clientQuoteByTitle,
    process01BuildUnderstanding: understanding,
    process02BuildSolutions: solutions,
    process03BuildMomentum: momentum,
    imageGallery,
    featuredVideoUrl,
  } = data

  const { allFile } = useStaticQuery(graphql`
    query GetGalleryImages {
      allFile(sort: { fields: relativePath, order: ASC }) {
        nodes {
          relativePath
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const images = allFile.nodes.filter(node =>
    imageGallery.includes(node.relativePath)
  )

  const relatedCaseStudies = caseStudies.filter(
    caseStudy => caseStudy.id !== id
  )

  return (
    <Layout>
      <Head title={title} />
      <CS01Intro
        title={title}
        introduction={introduction}
        client={client}
        services={services}
        clientQuote={clientQuote}
        clientQuoteBy={clientQuoteBy}
        clientQuoteByTitle={clientQuoteByTitle}
      />
      <CS02Video url={featuredVideoUrl} />
      <CS03Process
        understanding={understanding}
        solutions={solutions}
        momentum={momentum}
      />
      <CS04Gallery data={images} />
      <CS05Related data={relatedCaseStudies} />
    </Layout>
  )
}

export default CaseStudyTemplate
