import React from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import ProcessArrow from "../../images/icons/ProcessArrow.svg"

const ProcessWrapper = styled.section`
  padding: 0;

  @media screen and (min-width: 800px) {
    padding: 0 6rem;
  }
  .section-title {
    color: var(--navy);
    font-family: var(--subheading);
    font-size: 24px;
    line-height: 30px;

    @media screen and (min-width: 800px) {
      font-size: 36px;
    }
  }
  .process-wrapper {
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 800px) {
      grid-template-columns: 2fr 3fr;
    }

    .process-step h3 {
      color: var(--navy);
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .process-step .arrow {
      width: 85%;

      @media screen and (min-width: 800px) {
        width: 90%;
      }
    }

    .process-text {
      color: var(--navy);
      font-family: var(--paragraph);
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2rem;

      @media screen and (min-width: 800px) {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5rem;
      }

      padding: 1rem 0 0;
    }
  }
`

const CS03Process = ({ understanding, solutions, momentum }) => {
  return (
    <ProcessWrapper>
      <Grid>
        <h2 className="section-title">The process we took</h2>
        <div className="process-wrapper">
          <div className="process-step">
            <h3>
              01 <ProcessArrow className="arrow" />
            </h3>
            <h3>Build Understanding</h3>
          </div>
          <div className="process-text">{understanding}</div>
          <div className="process-step">
            <h3>
              02 <ProcessArrow className="arrow" />
            </h3>
            <h3>Build Solutions</h3>
          </div>
          <div className="process-text">{solutions}</div>
          <div className="process-step">
            <h3>
              03 <ProcessArrow className="arrow" />
            </h3>
            <h3>Build Momentum</h3>
          </div>
          <div className="process-text">{momentum}</div>
        </div>
      </Grid>
    </ProcessWrapper>
  )
}

export default CS03Process
