import React from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"

const IntroWrapper = styled.section`
  padding: calc(50px + 1rem) 0 1rem;
  @media screen and (min-width: 800px) {
    padding: calc(74px + 2rem) 6rem 1rem;
  }

  .case-study-title {
    color: var(--navy);
    font-family: "DIN Next LT Pro Bold", sans-serif;
    font-size: 24px;
    line-height: 30px;

    @media screen and (min-width: 800px) {
      font-size: 36px;
      line-height: 30px;
    }
  }

  .introduction {
    color: var(--navy);
    font-family: var(--paragraph);
    font-size: 18px;
    line-height: 22px;
    @media screen and (min-width: 800px) {
      font-size: 30px;
      line-height: 36px;
    }
  }

  .client-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem 0 0;

    @media screen and (min-width: 800px) {
      grid-template-columns: 1fr 2fr;
      margin: 8rem 0;
    }

    .info-title {
      color: var(--navy);
      font-family: var(--paragraph);
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      margin: 0 0 14px 0;

      @media screen and (min-width: 800px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .info-data {
      color: var(--navy);
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 14px;
      line-height: 14px;
      margin: 0 0 2rem 0;

      @media screen and (min-width: 800px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .service-list {
      list-style: none;
      margin: 0 0 2rem 0;
      padding: 0;

      @media screen and (min-width: 800px) {
        margin: 0;
      }

      .service {
        color: var(--navy);
        font-family: "Galano Grotesque Medium", sans-serif;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 14px;

        @media screen and (min-width: 800px) {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    .quote-wrapper {
      color: var(--gold);

      .quote,
      .quoteby {
        font-family: "Galano Grotesque Medium", sans-serif;
        font-size: 14px;
        line-height: 18px;

        @media screen and (min-width: 800px) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      .quote {
        margin: 0;
      }
    }
  }
`

const CS01Intro = ({
  title,
  introduction,
  client,
  services,
  clientQuote,
  clientQuoteBy,
  clientQuoteByTitle,
}) => {
  return (
    <IntroWrapper>
      <Grid>
        <h1 className="case-study-title">{title}</h1>
        <p className="introduction">{introduction}</p>
        <div className="client-wrapper">
          <div className="info-bar">
            <div className="client"></div>
            <h5 className="info-title">Client</h5>
            <p className="info-data">{client}</p>
            <h5 className="info-title">Services</h5>
            <ul className="service-list">
              {services.map(service => {
                return <li className="service">{service.title}</li>
              })}
            </ul>
          </div>
          <div className="quote-wrapper">
            <p className="quote">{`"${clientQuote}"`}</p>
            <p className="quoteby">{`- ${clientQuoteBy}, ${clientQuoteByTitle}`}</p>
          </div>
        </div>
      </Grid>
    </IntroWrapper>
  )
}
export default CS01Intro
