import React from "react"
import { useStaticQuery } from "gatsby"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import MoreWork from "../Layout/MoreWork"

const RelatedWrapper = styled.section`
  padding: 0;

  @media screen and (min-width: 800px) {
    padding: 0 6rem;
  }
  .related-title {
    text-transform: lowercase;
  }

  .related-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media screen and (min-width: 800px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
    }

    padding: 1rem 0 3rem;
  }
`

const CS05Related = ({ data }) => {
  const { allFile } = useStaticQuery(graphql`
    query GetRelatedImages {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return (
    <RelatedWrapper>
      <Grid>
        <h2 className="related-title">More projects</h2>
        <div className="related-wrapper">
          {data.map(post => {
            const { id, title, slug, featuredTagline, featuredImageUrl } = post

            const image = allFile.nodes.find(
              n => n.relativePath === featuredImageUrl
            )

            return (
              <MoreWork
                key={id}
                title={title}
                url={slug}
                tagline={featuredTagline}
                image={image.childImageSharp.fluid}
              />
            )
          })}
        </div>
      </Grid>
    </RelatedWrapper>
  )
}

export default CS05Related
