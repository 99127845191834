import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import RightArrowMedium from "../../images/icons/RightArrowMedium.svg"

const ItemWrapper = styled.article`
  .wrapper {
    display: flex;
    flex-direction: column;
    /* height: 30vh;

    @media screen and (min-width: 800px) {
      height: 25vh;
    } */
  }

  .wrapper:hover {
    .title,
    .subtitle {
      color: var(--yellow);
    }

    .hover-link,
    .hover-caption {
      display: flex;
    }

    .background-image::before {
      background-blend-mode: multiply;
    }
  }

  .image-wrapper {
    width: 100%;
    height: 30vh;

    @media screen and (min-width: 800px) {
      height: 25vh;
    }

    .hover-link,
    .hover-caption {
      color: var(--white);
      display: none;
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 16px;
      line-height: 22px;
    }

    .background-image {
      position: relative;

      .hover-caption {
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 1rem;
      }
      .hover-link {
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
      }
    }
  }

  .title-wrapper {
    min-height: 64px;
    padding: 1rem 0 0;

    .title {
      color: var(--navy);
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
  }
`

const MoreWork = ({ url, title, tagline, image }) => {
  return (
    <ItemWrapper>
      <div className="wrapper">
        <div className="image-wrapper">
          <Link className="image-cta" to={`/work/${url}`}>
            <BackgroundImage
              backgroundColor={`var(--navy)`}
              className="background-image"
              fluid={image}
              style={{ height: `100%` }}
            >
              <p className="hover-caption">{tagline}</p>
              <p className="hover-link">
                View case study <RightArrowMedium />
              </p>
            </BackgroundImage>
          </Link>
        </div>
        <div className="title-wrapper">
          <h2 className="title">{title}</h2>
        </div>
      </div>
    </ItemWrapper>
  )
}

export default MoreWork
